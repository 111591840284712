function CaseSubmitted() {
  return (
    <main>
      <span className="success">
        <span className="success-message">
          <p>Your response to this case has been submitted successfully.</p>
          <p>You can now close this page.</p>
        </span>
      </span>
    </main>
  );
}

export default CaseSubmitted;
