function CaseSubmitted() {
  return (
    <main>
      <span className="success">
        <span className="success-message">
          <p>Your request for new card has been submitted succesfully.</p>
          <p>You will recieve a new card in the email given</p>
          <p>You can now close this page.</p>
        </span>
      </span>
    </main>
  );
}

export default CaseSubmitted;
