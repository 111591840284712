import { Route, Routes } from "react-router-dom";

import CaseById from "./pages/cases/CaseById";
import CaseByIdUpload from "./pages/cases/CaseByIdUpload";
import Cases from "./pages/cases/Cases";
import CaseSubmitted from "./pages/cases/CaseSubmitted";
import CaseRefreshRequestSuccess from "./pages/cases/CaseRefreshRequestSuccess";
import Home from "./pages/Home";

import Refresh from "./components/Refresh";

function App() {
  return (
    <div className="App">
      <header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Flexible IT</h1>
          <Refresh />
        </div>
      </header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="cases" element={<Cases />}>
          <Route path=":caseId" element={<CaseById />} />
          <Route path="upload/:caseId" element={<CaseByIdUpload />} />
          <Route path="submitted" element={<CaseSubmitted />} />
          <Route
            path="refreshSuccessful"
            element={<CaseRefreshRequestSuccess />}
          />
        </Route>
      </Routes>
      <footer>
        <h5>Flexible IT {new Date().getFullYear()}</h5>
      </footer>
    </div>
  );
}

export default App;
