import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";

function RefreshButton() {
  const handleClick = () => {
    window.location.reload();
  };

  return (
    <button onClick={handleClick}>
      <FontAwesomeIcon icon={faSync} /> Refresh
    </button>
  );
}

export default RefreshButton;
