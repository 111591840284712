import React, { useCallback, useMemo } from "react";
import { Readable } from "form-data";
import { Box, Button, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from "react-dropzone";
import "./Dropzone.css";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#4d4d4d",
  outline: "none",
  transition: "border .24s ease-in-out",
};
const hasFilesStyle = { borderColor: "#2196f3" };
const acceptStyle = { borderColor: "#00e676" };
const rejectStyle = { borderColor: "#ff1744" };

function Dropzone({ filesToUpload, setFiles }) {
  const onDrop = useCallback(
    (droppedFiles) => {
      const uniqueFiles = droppedFiles.reduce((acc, file) => {
        if (
          !file.hasOwnProperty("path") ||
          !acc.some((ele) => ele.path === file.path)
        ) {
          acc.push(file);
        }
        return acc;
      }, filesToUpload);

      setFiles(uniqueFiles);
    },
    [filesToUpload],
  );

  const fileSizeValidator = (file) => {
    if (file.size > 10000000) {
      return {
        code: "file-too-big",
        message: "File is larger than 10MB",
      };
    }
  };

  const {
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    onDrop,
    noClick: true,
    useFsAccessApi: false,
    validator: fileSizeValidator,
  });

  const handlePaste = (e) => {
    Array.from(e.clipboardData.files).forEach(async (fileObject) => {
      const checkIfFileExists = (filename) => {
        return filesToUpload.some((file) => file.name === filename);
      };

      const createUniqueFileName = (filename) => {
        let count = 0;
        let uniqueFileName = filename;
        while (checkIfFileExists(uniqueFileName)) {
          count++;
          uniqueFileName = filename.replace(/\.(\w+)$/, `(${count}).$1`);
        }
        return uniqueFileName;
      };
      const uniqueFileName = createUniqueFileName(fileObject.name);
      let rtn = { name: uniqueFileName };
      await fileObject.arrayBuffer().then((resp) => (rtn.arrayBuffer = resp));

      onDrop([rtn]);
    });
  };

  const handleRemoveFile = (fileIndex) => {
    filesToUpload.splice(fileIndex, 1);
    setFiles(filesToUpload);
  };

  const acceptedFiles = filesToUpload.map((file, i) => (
    <li key={file.path || file.name}>
      <IconButton size="small" onClick={() => handleRemoveFile(i)}>
        <FontAwesomeIcon icon={faTrash} size="xs" />
      </IconButton>{" "}
      {file.name}
    </li>
  ));

  const rejectedFiles = fileRejections.map(({ file, errors }) => (
    <li key={file.path || file.name}>
      {file.name} {errors.map((e) => `[${e.message}]`)}
    </li>
  ));

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? hasFilesStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }));

  return (
    <div className="dropzone-container">
      <div {...getRootProps({ style })} onPaste={handlePaste}>
        <input {...getInputProps()} />
        <p>
          Drag 'n' drop files here, or select this box and paste an image with{" "}
          <i>Ctrl+V</i>
        </p>
        <div class="file-limit-description">(Less than 10MB each)</div>
        <Box mt={2}>
          <Button variant="contained" size="small" my={2} onClick={open}>
            Click to Upload Files
          </Button>
        </Box>
        {acceptedFiles.length > 0 && (
          <div className="file-list">
            <p>Files to upload:</p>
            <ul>{acceptedFiles}</ul>
          </div>
        )}
        {rejectedFiles.length > 0 && (
          <div className="rejected file-list">
            <p>Rejected files:</p>
            <ul>{rejectedFiles}</ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Dropzone;
